import { PathTypes } from 'recoil/path';
import { fetchGetApi } from './api';
import {
  KOREAN_REGEX,
  PATH_VALIDATE_TYPE,
  STUDENT_GRADE_LIST,
  app_link,
  productData,
  HyosungDisabledDates,
} from './constants';
import { lazy } from 'react';
import { TableRecoilType } from 'recoil/table';
import dayjs from 'dayjs';

// * 효성 점검 여부 체크
export const isHyosungDisable = () => {
  const currentDate = dayjs();
  let endTimeStr = '';
  let isNeedDisable = false;
  for (let i = 0; i < HyosungDisabledDates.length; i++) {
    const disabledStartDate = dayjs(HyosungDisabledDates[i].startDate);
    const disabledEndDate = dayjs(HyosungDisabledDates[i].endDate);

    if (currentDate.isAfter(disabledStartDate) && currentDate.isBefore(disabledEndDate)) {
      isNeedDisable = true;
      endTimeStr = HyosungDisabledDates[i].endTimeStr;
    }
  }

  return { res: isNeedDisable, endTimeStr };
};

export function validateUseRecoilPath(
  path: PathTypes,
  pathValidateArr: PATH_VALIDATE_TYPE,
  recoilState: any,
): { [key: string]: any } | undefined {
  const { rootPath, childPath } = path;

  if (childPath) {
    const isValidate = pathValidateArr[rootPath].validateChildPath.includes(childPath);

    if (isValidate) {
      const state = recoilState[rootPath][childPath];
      return {
        ...state,
      };
    } else {
      return undefined;
    }
  } else {
    const tmp_child_path = '';
    if (rootPath) {
      const isValidate = pathValidateArr[rootPath].validateChildPath.includes(tmp_child_path);
      if (isValidate && recoilState[rootPath]) {
        const state = recoilState[rootPath]['root'];
        return {
          ...state,
        };
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
}

export function autoHypenPhone(str: string) {
  str = str.replace(/[^0-9]/g, '');
  let tmp = '';
  if (str.length < 4) {
    return str;
  } else if (str.length < 7) {
    tmp += str.substring(0, 3);
    tmp += '-';
    tmp += str.substring(3);
    return tmp;
  } else if (str.length < 11) {
    tmp += str.substring(0, 3);
    tmp += '-';
    tmp += str.substring(3, 6);
    tmp += '-';
    tmp += str.substring(6);
    return tmp;
  } else {
    tmp += str.substring(0, 3);
    tmp += '-';
    tmp += str.substring(3, 7);
    tmp += '-';
    tmp += str.substring(7);
    return tmp;
  }
}

export function autoBrn(businessNumber: string) {
  return businessNumber
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, '$1-$2-$3')
    .replace(/(\-{1,2})$/g, '');
}

export const validateKorean = (value: string): boolean => {
  if (KOREAN_REGEX.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const validatePassword = (value: string): boolean => {
  let status = false;
  status = validateKorean(value);
  if (status) return false;
  else {
    const passwordPattern =
      /^(((?=.*[a-z])(?=.*[!@#$%&*_+]))|((?=.*[!@#$%&*_+])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9])))(?!.*[A-Zㄱ-힣])(\S){8,20}$/;
    status = passwordPattern.test(value);

    return status;
  }
};

export const makeYearArr = () => {
  const year = new Date().getFullYear();
  const year_arr = [];
  for (let i = year; i > 1979; i--) {
    year_arr.push(`${year}년`);
  }
  return year_arr;
};

export const pad = (num: number, width: number) => {
  const return_number = num + '';
  return return_number.length >= width
    ? return_number
    : new Array(width - return_number.length + 1).join('0') + return_number;
};

export const setModalTitle = ({ title, subTitle, target }: { title: string; subTitle?: string; target?: string }) => {
  let tmp_title = title;
  let tmp_sub_title = subTitle;
  if (target) {
    tmp_title = title.replace('target', target);
    if (tmp_sub_title) {
      tmp_sub_title = tmp_sub_title.replace('target', target);
      return `<span>${tmp_title}</span>${tmp_sub_title ? `<span class="sub-title">${tmp_sub_title}</span>` : ''}`;
    }
  } else if (tmp_sub_title) {
    return `<span>${tmp_title}</span>${tmp_sub_title ? `<span class="sub-title">${tmp_sub_title}</span>` : ''}`;
  }
  return tmp_title;
};

export const makeCommaAsThousandSeparator = (value: string | number): string => {
  return value.toLocaleString('ko-KR');
};

export function makeUri(
  start: string,
  queries: { target?: string; sort?: string; page?: number; max?: number; searchKeyword?: string },
) {
  const { target, sort, page, max, searchKeyword } = queries;
  const items = [];
  if (target) items.push(`sortKey=${target}`);
  if (sort) items.push(`sortValue=${sort}`);
  if (page) items.push(`page=${page}`);
  if (max) items.push(`limit=${max}`);
  if (searchKeyword) items.push(`search=${escapeSpecialCharacter(searchKeyword)}`);
  // 현재 필요x (일단 남겨둠)
  const uri = start + '?' + items.join('&');
  return uri;
}

export const escapeSpecialCharacter = (keyword: string) => {
  const encode_keyword: string = encodeURIComponent(keyword);
  return encode_keyword;
};

export function getKorPermission(permission: number): { type: string; label: string } {
  let res = { type: '', label: '' };

  switch (permission) {
    case 1:
      res = { type: 'color #FF65BD', label: '대표 관리자' };
      break;
    case 2:
      res = { type: 'color #2b8ad1', label: '부원장' };
      break;
    case 3:
      res = { type: 'color #2b8ad1', label: '실장' };
      break;
    case 4:
      res = { type: 'color #2b8ad1', label: '선생님' };
      break;
    case 5:
      res = { type: 'color #2b8ad1', label: '기타' };
      break;
  }

  return res;
}

export function getKorStatus(status: number): { type: string; label: string } {
  let res = { type: '', label: '' };

  switch (status) {
    case 1:
      res = { type: 'color #2b8ad1', label: '재원생' };
      break;
    case 2:
      res = { type: 'color #2b8ad1', label: '휴원생' };
      break;
    case 3:
      res = { type: 'color #2b8ad1', label: '퇴원생' };
      break;
    case 4:
      res = { type: 'color #2b8ad1', label: '미등록생' };
      break;
  }

  return res;
}

// Date를 string으로 바꿔주는 함수
export function setLocaleDateString(date: Date | null, type: string, month?: boolean): string {
  let new_date: Date | null;
  if (date !== null) {
    new_date = date;
  } else {
    new_date = new Date();
  }
  const y = new_date.getFullYear();
  const m = new_date.getMonth() + 1;
  const d = new_date.getDate();

  let res = '';

  if (type == 'kor') {
    res = `${y}년 ${Number(m) < 10 ? '0' + m : m}월`;
    if (!month) res += ` ${Number(d) < 10 ? '0' + d : d}일`;
  } else {
    res = `${y}${type}${Number(m) < 10 ? '0' + m : m}`;
    if (!month) res += `${type}${Number(d) < 10 ? '0' + d : d}`;
  }

  return res;
}

// 선생님 관리 페이지에서 테이블 row 클릭 시, 선생님의 클래스 정보 요청 하는 api
export const addClassesInTeacher = async (center_id: number, item: TableItemsType) => {
  // 한 번만 요청 될 수 있도록 rname이 null인 경우로 조건 잡음

  if (item && item.teacher_id && item.details && item.details.rname === null) {
    const teacher_id = item.teacher_id;
    try {
      const res: GetClassesInTeacher = await fetchGetApi(
        `/customers/${center_id}/accounts/teachers/${teacher_id}/classes`,
      )();

      let new_details: { class_name: string; sub_class_name?: string };

      if (res.result) {
        if (res.data.class_name) {
          new_details = { class_name: res.data.class_name };
        } else {
          new_details = { class_name: '배정된 클래스가 없습니다.' };
        }

        if (res.data.sub_class_name) {
          new_details = { class_name: new_details.class_name, sub_class_name: res.data.sub_class_name };
        }
        item.details = new_details;
      }
    } catch (error) {
      console.log(error);
    }
  }
};

// 클래스 관리 페이지에서 테이블 row 클릭 시, 클래스에 속해 있는 학생들 정보 요청 하는 api
export const addUsersInClass = async (center_id: number, item: TableItemsType) => {
  // 한 번만 요청 될 수 있도록 details.students가 없는 경우로 조건 잡음
  if (item && item.id && item.details && !item.details.student) {
    const class_id = item.id;
    try {
      const res: GetStudentsInClassResType = await fetchGetApi(
        `/customers/${center_id}/classes/${class_id}/students`,
      )();
      let new_deatils: { [key: string]: string } = {};
      if (res.data.results.length) {
        const rows = res.data.results;
        for (let i = 0, leng = rows.length; i < leng; i++) {
          if (i > 0) {
            new_deatils = { ...new_deatils, [`s${i}`]: `${rows[i].name}/${rows[i].accountId}` };
          } else {
            if (item.details && item.details['sub_teachers']) {
              new_deatils = {
                teacher: item.details['teacher'],
                sub_teachers: item.details['sub_teachers'],
                student: `${rows[i].name}/${rows[i].accountId}`,
              };
            } else {
              new_deatils = { teacher: item.details['teacher'], student: `${rows[i].name}/${rows[i].accountId}` };
            }
          }
          item.details = new_deatils;
        }
      } else {
        new_deatils = { ...item.details, student: '등록된 학생이 없습니다.' };
        item.details = new_deatils;
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export const getKorNotice = (type: number) => {
  let res = { type: '', label: '' };
  switch (type) {
    case 1:
      res = { type: 'color #2b8ad1', label: '전체공지' };
      break;
    case 2:
      res = { type: 'color #2b8ad1', label: '수업공지' };
      break;
    case 3:
      res = { type: 'color #2b8ad1', label: '반별공지' };
      break;
    case 21:
      res = { type: 'color #2b8ad1', label: '공지사항' };
  }
  return res;
};

export const setStartOrEndDate = (buttonText: string, calendarType: 'start' | 'end') => {
  const return_obj: { start: Date; end: Date } = { start: new Date(), end: new Date() };
  const today = setLocaleDateString(new Date(), '/');
  switch (buttonText) {
    case `두달 전`:
      return_obj.start = getLastMonth(today, 2, 'start');
      return_obj.end = getLastMonth(today, 1, 'end');
      break;
    case `지난 달`:
      return_obj.start = getLastMonth(today, 1, 'start');
      return_obj.end = getLastMonth(today, 0, 'end');
      break;
    case `이번 달`:
      return_obj.start = getLastMonth(today, 0, 'start');
      break;
    case `2년 전`:
      return_obj.start = getLastYear(today, 2, 'start');
      return_obj.end = getLastYear(today, 2, 'end');
      break;
    case `작년`:
      return_obj.start = getLastYear(today, 1, 'start');
      return_obj.end = getLastYear(today, 1, 'end');
      break;
    case `올해`:
      return_obj.start = getLastYear(today, 0, 'start');
      break;
    case `2일 전`:
      return_obj.start = getLastDate(today, 2, 'start');
      break;
    case `어제`:
      return_obj.start = getLastDate(today, 1, 'start');
      break;
    case `오늘`:
      return_obj.start = getLastDate(today, 0, 'start');
      break;
  }

  if (calendarType === 'start') {
    return return_obj.start;
  } else {
    return return_obj.end;
  }
};

// 이전일을 구하는 함수
export const getLastDate = (today: string, gap: number, type: 'start' | 'end') => {
  const date = new Date(today);
  date.setDate(date.getDate() - gap);
  return date;
};

// 이전달을 구하는 함수
export const getLastMonth = (today: string, gap: number, type: 'start' | 'end') => {
  const date = new Date(today);
  date.setMonth(date.getMonth() - gap);
  date.setDate(type === 'start' ? 1 : 0);
  return date;
};

// 이전년을 구하는 함수
export const getLastYear = (today: string, gap: number, type: 'start' | 'end') => {
  const date = new Date(today);
  date.setFullYear(date.getFullYear() - gap);
  date.setMonth(type === 'start' ? 0 : gap === 0 ? date.getMonth() : 12);
  date.setDate(type === 'start' ? 1 : 0);
  return date;
};

// 학년의 label 구하는 함수
export const getStudentGradeKor = (grade: string): string => {
  const find_grade_obj = STUDENT_GRADE_LIST.find(studentGrade => studentGrade.id == grade);
  if (find_grade_obj) {
    return find_grade_obj.name.replace('학교 ', '').replace('학년', '');
  } else {
    return '';
  }
};

export const setGradeToKor = (grade: string) => {
  switch (grade) {
    case 'a1':
      return '초등학교 1학년';
    case 'a2':
      return '초등학교 2학년';
    case 'a3':
      return '초등학교 3학년';
    case 'a4':
      return '초등학교 4학년';
    case 'a5':
      return '초등학교 5학년';
    case 'a6':
      return '초등학교 6학년';
    case 'b1':
      return '중학교 1학년';
    case 'b2':
      return '중학교 2학년';
    case 'b3':
      return '중학교 3학년';
    case 'c1':
      return '고등학교 1학년';
    case 'c2':
      return '고등학교 2학년';
    case 'c3':
      return '고등학교 3학년';
    case 'etc':
      return '기타';
    default:
      return '';
  }
};

export const setKorToGrade = (grade_string: string) => {
  switch (grade_string) {
    case '초등학교 1학년':
      return 'a1';
    case '초등학교 2학년':
      return 'a2';
    case '초등학교 3학년':
      return 'a3';
    case '초등학교 4학년':
      return 'a4';
    case '초등학교 5학년':
      return 'a5';
    case '초등학교 6학년':
      return 'a6';
    case '중학교 1학년':
      return 'b1';
    case '중학교 2학년':
      return 'b2';
    case '중학교 3학년':
      return 'b3';
    case '고등학교 1학년':
      return 'c1';
    case '고등학교 2학년':
      return 'c2';
    case '고등학교 3학년':
      return 'c3';
    case '기타':
      return 'etc';
    default:
      return '';
  }
};

export const setKorToGrade2 = (grade_string?: string) => {
  switch (grade_string) {
    case '초등학교 1학년':
      return '초1';
    case '초등학교 2학년':
      return '초2';
    case '초등학교 3학년':
      return '초3';
    case '초등학교 4학년':
      return '초4';
    case '초등학교 5학년':
      return '초5';
    case '초등학교 6학년':
      return '초6';
    case '중학교 1학년':
      return '중1';
    case '중학교 2학년':
      return '중2';
    case '중학교 3학년':
      return '중3';
    case '고등학교 1학년':
      return '고1';
    case '고등학교 2학년':
      return '고2';
    case '고등학교 3학년':
      return '고3';
    case '기타':
      return '기타';
    default:
      return '전체';
  }
};

export const setTestNameToKor = (testType: string) => {
  switch (testType) {
    case 'example':
      return '예문 테스트';
    case 'listening':
      return '듣기 테스트';
    case 'spell':
      return '철자 테스트';
    case 'meaning':
      return '의미 테스트';
  }
};

export const setPermissionToNum = (permission: string) => {
  switch (permission) {
    case '부원장':
      return 2;
    case '실장':
      return 3;
    case '선생님':
      return 4;
    case '기타':
      return 5;
  }
};

export function getIdRCheckday(rcheckday: string) {
  const rcheckday_arr = rcheckday.split(', ').filter(item => item);

  const days: { [key: string]: string } = {
    일: '0',
    월: '1',
    화: '2',
    수: '3',
    목: '4',
    금: '5',
    토: '6',
  };

  return rcheckday_arr.map(item => days[item]).toString();
}

export const preProcessCreateClassData = (data: RegistrationDatasType): Partial<ClassDataType> => {
  const keys = Object.keys(data);
  const return_data: Partial<ClassDataType> = {};
  const study_start_time =
    data.study_start_time && data.study_start_time.value
      ? data.study_start_time.value.replace('시 ', '').replace('분', '')
      : '';

  keys.forEach(key => {
    switch (key) {
      case 'teacher_name':
        return_data['teacher_id'] = data.teacher_name.id;
        break;
      case 'name':
        return_data['name'] = data.name.value;
        break;
      case 'study_days':
        return_data['study_days'] = getIdRCheckday(data.study_days.value);
        break;
      case 'study_start_time':
        return_data['study_start_time'] = study_start_time.length === 3 ? '0' + study_start_time : study_start_time;
        break;
      case 'limit_time':
        return_data['limit_time'] = Number(data.limit_time.value.replace('초', ''));
        break;
      case 'use_push':
        return_data['use_push'] = data.use_push.value === '사용' ? 1 : 0;
        break;
      case 'use_rank':
        return_data['use_rank'] = data.use_rank.value === '사용' ? 1 : 0;
        break;
    }
  });

  return return_data;
};

export const preProcessUpdateStudentData = (data: RegistrationDatasType): Partial<StudentDataType> => {
  const keys = Object.keys(data);
  const return_data: Partial<StudentDataType> = {};

  keys.forEach(key => {
    switch (key) {
      case 'class_name':
        return_data['class_id'] = data.class_name.id;
        break;
      case 'grade':
        return_data['grade'] = setKorToGrade(data.grade.value);
        break;
      case 'name':
        return_data['name'] = data.name.value;
        break;
      case 'phone':
        return_data['phone'] = data.phone.value;
        break;
      case 'accountId':
        return_data['accountId'] = data.accountId.value;
        break;
      case 'password':
        return_data['password'] = data.password.value;
        break;
      case 'school':
        return_data['school'] = data.school.value;
        break;
      case 'birthdate':
        return_data['birthdate'] = data.birthdate.value;
        break;
      case 'parent_name':
        return_data['parent_name'] = data.parent_name.value;
        break;
      case 'parent_phone':
        return_data['parent_phone'] = data.parent_phone.value;
        break;
    }
  });

  return return_data;
};

export const sortingStudyday = (study_days: string): string => {
  const weekday = ['일', '월', '화', '수', '목', '금', '토'];
  const rcheckday_arr: number[] = study_days
    .split(',')
    .map(day => weekday.findIndex((element, eIdx) => eIdx == Number(day)));
  // rcheckday_arr.sort((a, b) => a - b);

  return rcheckday_arr.map(day => weekday[day]).join(', ');
};

export const setLimitTimeToModalContent = (limit_time: number) => {
  return limit_time + '초';
};

export const setStudyStartTimeToModalContent = (study_start_time: string) => {
  const study_start_time_array = study_start_time.split('');
  study_start_time_array.splice(2, 0, '시 ');
  study_start_time_array.push('분');
  return study_start_time_array.join('');
};

export const beforeUnLoadFunction = (event: BeforeUnloadEvent) => {
  const user = sessionStorage.getItem('user');
  if (user) {
    event.preventDefault();
    event.returnValue = '';
  }
};

export const retryLazy = (componentImport: any) =>
  lazy(async () => {
    const pageAlreadyRefreshed = JSON.parse(window.localStorage.getItem('pageRefreshed') || 'false');
    try {
      const component = await componentImport();
      window.localStorage.setItem('pageRefreshed', 'false');

      return component;
    } catch (error) {
      if (!pageAlreadyRefreshed) {
        window.removeEventListener('beforeunload', beforeUnLoadFunction);
        window.localStorage.setItem('pageRefreshed', 'true');
        return window.location.reload();
      }
      throw error;
    }
  });

export const setSettingRangeToKor = (selected_units_squence: string | null, words_count: number): string => {
  let return_value = '';
  if (selected_units_squence) {
    return_value += '단원 일부, ';
  } else {
    return_value += '단원 전체, ';
  }

  if (words_count) {
    return_value += `${words_count}개 분할`;
  } else {
    return_value += '단원별 설정';
  }

  return return_value;
};

export const setUseImageToKor = (use_image: number): string => {
  if (use_image) {
    return '연상 학습';
  } else {
    return '일반 학습';
  }
};

export const setStudyMethodToKor = (study_method: number): string => {
  if (study_method) {
    return '단계별 학습';
  } else {
    return '자율 학습';
  }
};

export const setAutoWrongToKor = (auto_wrong: number): string => {
  if (auto_wrong) {
    return '자동 생성';
  } else {
    return '사용 안함';
  }
};

export const makeButtonColorForTextInquiryTable = (key: string, status: number) => {
  if (key == 'basic' || key == 'grammar_b') {
    if (status == 2) {
      return 'green_2';
    } else if (status == 3) {
      return 'green_3';
    } else {
      return 'green_3';
    }
  } else if (key == 'advanced' || key == 'grammar_d') {
    if (status == 2) {
      return 'blue_2';
    } else if (status == 3) {
      return 'blue_3';
    } else {
      return 'blue_3';
    }
  } else {
    if (status == 2) {
      return 'orange_4';
    } else if (status == 3) {
      return 'orange_5';
    } else {
      return 'orange_5';
    }
  }
};

export const makeButtonLabelForTextInquiryTable = (key: string, status: number, complete_date?: string) => {
  if (status == 0) {
    return `${complete_date} 완료`;
  }
  if (key == 'basic' || key == 'grammar_b') {
    if (status == 1) {
      return '기본학습 중';
    } else if (status == 3) {
      return '재학습 중';
    } else {
      return '기본학습 전';
    }
  } else if (key == 'advanced' || key == 'grammar_d') {
    if (status == 1) {
      return '심화학습 중';
    } else if (status == 3) {
      return '재학습 중';
    } else {
      return '심화학습 전';
    }
  } else {
    if (status == 1) {
      return '이론학습 중';
    } else if (status == 3) {
      return '재학습 중';
    } else {
      return '이론학습 전';
    }
  }
};
export const changeCalendarWeekdayName = (dayOfWeek: string) => {
  switch (dayOfWeek) {
    case '일':
      return 'Sun';
    case '월':
      return 'Mon';
    case '화':
      return 'Tue';
    case '수':
      return 'Wed';
    case '목':
      return 'Thu';
    case '금':
      return 'Fri';
    case '토':
      return 'Sat';
    default:
      return '';
  }
};

export const setCalendarHeightAuto = (direction?: 'previous' | 'next') => {
  setTimeout(() => {
    let max_height = 0;
    const class_el_array = document.getElementsByClassName('MuiDayPicker-monthContainer');
    if (class_el_array.length) {
      for (let i = 0; i < class_el_array.length; i++) {
        const class_el = class_el_array.item(i);
        if (class_el) {
          const month_height = (class_el as HTMLElement).clientHeight;
          if (direction) {
            if (i == 0) {
              max_height = month_height;
            }
          } else {
            if (class_el) {
              if (max_height < month_height) {
                max_height = month_height;
              }
            }
          }
        }
      }

      const calendar_body_el = document.querySelector('.PrivatePickersSlideTransition-root');

      if (calendar_body_el) {
        (calendar_body_el as HTMLElement).style.height = `${max_height + 2}px`;
      }
    }
  }, 0);
};

export const makeClassNameInputInTable = (key: string) => {
  if (['class_name', 'grade', 'birthdate'].includes(key)) {
    return 'select-input';
  } else {
    return '';
  }
};

export const makePlaceholderInputInTable = (key: keyof UploadStudentDataType, error?: ErrorPlaceholder) => {
  if (error) {
    const error_placeholder_obj = {
      longvoca_null_class: '선택하세요',
      longvoca_err_grade: '선택하세요',
      longvoca_null_name: '필수항목',
      longvoca_already_id: '중복 아이디',
      longvoca_space_id: '공백입력불가',
      longvoca_err_id: '공백, 한글입력불가',
      longvoca_require_id: '필수항목',
      longvoca_require_pp: '필수항목',
      longvoca_err_pw: '최소 8자 이상',
      longvoca_err_class: '선택하세요',
    };

    return error_placeholder_obj[error];
  } else {
    const placeholde_obj = {
      class_name: '-',
      grade: '-',
      name: '필수항목',
      phone: '-',
      accountId: '필수항목',
      password: '필수항목',
      school: '-',
      birthdate: '-',
      parent_name: '-',
      parent_phone: '숫자로만 입력',
    };

    return placeholde_obj[key];
  }
};

export const setLabelToNoticeType = (key: string) => {
  const noticeType: { [key: string]: number } = {
    전체공지: 1,
    수업공지: 2,
    반별공지: 3,
  };

  return noticeType[key];
};

export const formatUnixTime = (unixTime: number) => {
  const date = new Date(unixTime * 1000);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatStringTime = (date_string: string) => {
  const date = new Date(date_string);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const handleDailyReportRowClick = async (
  item: any,
  setRecoilState: (valOrUpdater: TableRecoilType | ((currVal: TableRecoilType) => TableRecoilType)) => void,
  tableKey: string,
  cid: number,
  spreadForSearch?: boolean,
) => {
  if (!item.show_details) {
    const dailyReportDetailUri = `/customers/${cid}/records/detail/${item.record_id}?type=${item.type}`;
    try {
      const response = await fetchGetApi(dailyReportDetailUri)();
      let idx = 0;
      setRecoilState((prev: any) => ({
        ...prev,
        [tableKey]: {
          ...prev[tableKey],
          tableItems: prev[tableKey].tableItems.map((tableItem: any, tIdx: any) => {
            if (tableItem.record_id === item.record_id) {
              idx = tIdx;
              return {
                ...tableItem,
                details: response.data,
                show_details: true,
                checked: true,
              };
            }
            return {
              ...tableItem,
              show_details: false,
              checked: false,
            };
          }),
          checkedCount: 1,
          checkedIdx: [idx],
        },
      }));
    } catch (err) {
      console.error(err);
    }
  } else {
    setRecoilState((prev: any) => {
      return {
        ...prev,
        [tableKey]: {
          ...prev[tableKey],
          tableItems: prev[tableKey].tableItems.map((tableItem: any) => {
            return {
              ...tableItem,
              show_details: false,
              checked: false,
            };
          }),
          checkedCount: 0,
          checkedIdx: [],
        },
      };
    });
  }
};

export function formatDate(date: Date | null) {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}

export function convertSubject(subject: string) {
  switch (subject) {
    case 'listening':
      return '듣기';
    case 'writing':
      return '쓰기';
    case 'speaking':
      return '말하기';
    case 'reading':
      return '읽기';
    default:
      return subject;
  }
}

export function makeHiddenText({ text }: { text: string }): string {
  let hiddenCount = 4;
  if (text.length <= hiddenCount) {
    return text;
  } else if (text.length > 4 && text.length <= 6) {
    hiddenCount = 3;
  }

  const hiddenText = [...Array.from(Array(hiddenCount).keys())]
    .map(() => {
      return '*';
    })
    .join('');

  return text.slice(0, text.length - hiddenCount) + hiddenText;
}

export function getMaxStudents(student: number) {
  switch (true) {
    case student <= 15:
      return 15;
    case student <= 30:
      return 30;
    case student <= 50:
      return 50;
    default:
      return 50 + Math.ceil((student - 50) / 50) * 50;
  }
}

export function convertModulesLabel(
  idx: number,
  mode: 'basic' | 'advanced' | 'speak' | 'grammar_t' | 'grammar_b' | 'grammar_d',
) {
  let return_value = '';
  switch (idx) {
    case 1:
      return_value = 'FLASH CARD';
      break;
    case 2:
      return_value = 'WORD BINGO';
      break;
    case 3:
      return_value = 'POP QUIZ';
      break;
    case 4:
      return_value = 'DICTATION';
      break;
    case 5:
      return_value = 'WRITING';
      break;
    case 6:
      return_value = 'SPEAKING';
      break;
    case 7:
      return_value = '추천 학습영상';
      break;
    case 8:
      return_value = '주요예문 점검';
      break;
    default:
      return_value = '';
      break;
  }

  if (return_value == 'SPEAKING' && mode == 'speak') {
    return_value = '실전 회화학습';
  }

  return return_value;
}

export const setTextShadow = ({
  color,
  sizeShadow,
  directionShadow,
}: {
  color: string;
  directionShadow: string;
  sizeShadow: number;
}): string => {
  let textShadow = '';

  for (let i = 0, len = sizeShadow; i < len; i++) {
    switch (directionShadow) {
      case 'top':
        textShadow += `0 -${i}px 0 ${color},`;
        break;
      case 'right':
        textShadow += `${i}px 0 0 ${color},`;
        break;
      case 'bottom':
        textShadow += `0 ${i}px 0 ${color},`;
        break;
      case 'left':
        textShadow += `-${i}px 0 0 ${color},`;
        break;
      case 'top-left':
        textShadow += `-${i}px -${i}px 0 ${color},`;
        break;
      case 'top-right':
        textShadow += `${i}px -${i}px 0 ${color},`;
        break;
      case 'bottom-left':
        textShadow += `-${i}px ${i}px 0 ${color},`;
        break;
      case 'bottom-right':
        textShadow += `${i}px ${i}px 0 ${color},`;
        break;
      default:
        textShadow += `${i}px ${i}px 0 ${color},`;
        break;
    }
  }

  textShadow = textShadow.slice(0, -1);
  return textShadow;
};

export const emptyCache = () => {
  window.removeEventListener('beforeunload', beforeUnLoadFunction);
  window.location.reload();
};

export const makeNoticeMessage = ({
  notice_type,
  title,
  content,
  center_name,
}: {
  notice_type: string;
  title: string;
  content: string;
  center_name: string;
}) => {
  return `
  [${center_name} 수업 및 과제 공지 안내]<br /><br />
  ■ 구분 : ${notice_type}<br />
  ■ 제목 : ${title}<br />
  ■ 내용 : <span style="word-break: break-all;">${content}</span>`;
};

export const makeCounselMessage = ({
  center_name,
  class_name,
  student_name,
  postdate,
  content,
}: {
  center_name: string;
  class_name: string;
  student_name: string;
  postdate: string;
  content: string;
}) => {
  return `[${center_name} 상담내용]<br /><br />■ 클래스 : ${class_name}<br />■ 이름 : ${student_name}<br />■ 일자 : ${postdate}<br />■ 내용 : ${content}`;
};

export const makeAwardMessage = ({
  center_name,
  class_name,
  student_name,
  current_month,
  rank,
}: {
  center_name: string;
  class_name: string;
  student_name: string;
  current_month: string;
  rank: string;
}) => {
  return `[${center_name} 성적 우수자 결과]<br /><br />
        ■ 클래스 : ${class_name}<br />
        ■ 이름 : ${student_name}<br />
        ■ 기간 : ${current_month}<br />
        ■ 순위 : ${rank}<br />
        <br />
        <span id="comment-content">남기실 코멘트를 기록해주세요.</span>
        <br /><br />
        ▶ 우수상장 조회<br />
        <span style="color: #ddd;">: 수신 메시지에는 링크가 삽입됩니다.</span>
        `;
};

export const makeReportMessage = ({
  center_name,
  student_name,
  book_name,
  unit_name,
  score,
}: {
  center_name: string;
  student_name: string;
  book_name: string;
  unit_name: string;
  score: string;
}) => {
  return `[${center_name} 성적결과]<br /><br />
        ■ 성명 : ${student_name}<br />
        ■ 교재 : ${book_name}<br />
        ■ 차시 : ${unit_name}<br />
        ■ 점수 : ${score}<br />
        <br />
        <span id="comment-content">남기실 성적 코멘트를 입력해주세요.</span>
        <br /><br />
        ▶ 성적표 보기<br />
        <span style="color: #ddd;">: 수신 메시지에는 링크가 삽입됩니다.</span>
        <br /><br />
        위 링크를 클릭하여 확인해주세요:)
        `;
};

export function calculateMonthlyFees(students: number, period: 'month' | 'sixMonth' | 'year') {
  // 엑셀 시트에 해당하는 학생 수의 데이터 찾기
  const data = productData[period].find(item => item.students <= students);

  if (data) {
    // 초과되는 학생 수 계산
    const excessStudents = students - data.students;

    // 산정 월 이용료 계산 (월 정기상품 단가 + 초과금 * 초과되는 학생 수)
    let calculatedMonthlyFee = data.monthlyFee;
    if (excessStudents > 0) {
      calculatedMonthlyFee += data.excessFee * excessStudents;
    }

    // 산정 월 이용료가 500명 상품 단가를 초과하는 경우 500명 상품 단가로 조정
    const fee500 = productData[period].find(item => item.students === 500);
    if (fee500) {
      if (calculatedMonthlyFee > fee500.monthlyFee) {
        calculatedMonthlyFee = fee500.monthlyFee;
      }
    }

    let unitPrice;

    if (period === 'month') {
      unitPrice = Math.round(calculatedMonthlyFee / students);
    } else if (period === 'sixMonth') {
      unitPrice = Math.round(calculatedMonthlyFee / 6 / students);
    } else {
      unitPrice = Math.round(calculatedMonthlyFee / 12 / students);
    }

    return {
      calculatedMonthlyFee: calculatedMonthlyFee, // 최종 산정금액
      unitPrice, // 단가
      excessFee: data.excessFee, // 초과인원 추가금
    };
  } else {
    // 학생 수에 해당하는 데이터가 없을 경우 15명 이하로 적용

    if (period === 'month') {
      return {
        calculatedMonthlyFee: 84000,
        unitPrice: 5600,
        excessFee: 5000,
      };
    } else if (period === 'sixMonth') {
      return {
        calculatedMonthlyFee: 420000,
        unitPrice: 4667,
        excessFee: 5000,
      };
    } else {
      return {
        calculatedMonthlyFee: 504000,
        unitPrice: 2800,
        excessFee: 5000,
      };
    }
  }
}

export const convertPaymentKindLabel = (payment: string) => {
  switch (payment) {
    case '가상계좌':
      return 'VA';
    case '전용계좌':
      return 'OA';
    case '카드결제':
      return 'AC';
    case '키인결제':
      return 'CD';
  }
};

export const convertLabelToPaymentKind = (label: string) => {
  switch (label) {
    case 'VA':
      return '가상계좌';
    case 'OA':
      return '전용계좌';
    case 'AC':
      return '카드결제';
    case 'CD':
      return '키인결제';
    default:
      return '';
  }
};

export const convertMonthToNumber = (month: string) => {
  switch (month) {
    case 'month':
      return '1';
    case 'sixMonth':
      return '6';
    case 'year':
      return '12';
    default:
      return '';
  }
};

export function autoHyphenCard(str: string) {
  str = str.replace(/[^0-9]/g, '');

  let tmp = '';

  for (let i = 0; i < str.length; i += 4) {
    tmp += str.substring(i, i + 4);
    tmp += '-';
  }

  return tmp.slice(0, tmp.length - 1);
}

export function autoHyphenBusinessNumber(str: string) {
  str = str.replace(/[^0-9]/g, '');

  let tmp = '';

  for (let i = 0; i < str.length; i++) {
    if (i === 3 || i === 5) {
      tmp += '-';
    }
    tmp += str[i];
  }

  return tmp;
}

export function calculateMonthPeriod() {
  const currentDate = dayjs();
  const lastDay = currentDate.endOf('month').date();

  const startDate = currentDate.format('YYYY-MM-DD');
  const endDate = dayjs(currentDate).date(lastDay).format('YYYY-MM-DD');

  return {
    startDate,
    endDate,
  };
}

export function getPeriodOfUse(period: string) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  let endDate;

  if (currentDay <= 5) {
    endDate = new Date(currentYear, currentMonth + parseInt(period, 10), 0);
  } else {
    const remainingDays = new Date(currentYear, currentMonth + 1, 0).getDate() - currentDay + 1;
    endDate = new Date(currentYear, currentMonth + parseInt(period, 10), remainingDays);
  }

  const lastDay = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);

  const today = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(currentDay).padStart(2, '0')}`;
  const formattedEndDate = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(
    2,
    '0',
  )}-${lastDay.getDate()}`;

  if (period === '1') {
    return calculateMonthPeriod();
  } else {
    return {
      startDate: today,
      endDate: formattedEndDate,
    };
  }
}

export function calculateProPayment(originFee: number) {
  const currentDate = dayjs();
  const lastDay = currentDate.endOf('month').date();

  const calcDay = lastDay - currentDate.date() + 1;

  const calcFee = Math.floor((originFee / lastDay) * calcDay);

  const truncatedCalcFee = Math.floor(calcFee / 10) * 10;

  return truncatedCalcFee;
}

export const convertPaymentFailMessage = (message: string) => {
  switch (message) {
    case '유효기간경과':
      return '유효기간이 일치하지 않습니다.\r다시 입력해주세요.';
    case '생년월일 불일치':
      return '생년월일이 일치하지 않습니다.\r생년월일 3회 입력 오류시 온라인결제가 제한됩니다.\r다시 입력해주세요.';
    case '카드번호 오류':
      return '카드번호가 일치하지 않습니다.\r다시 입력해주세요.';
    case '비밀번호횟수초과':
      return '입력 오류 횟수 초과로 입력이 제한됩니다.\r카드사 측에 문의해주세요.';
    case '잔액부족':
      return '카드잔액이 부족합니다.\r다른 카드 결제 또는 카드 잔액을 확인해주세요.';
    case '할부개월수 오류':
      return '할부 개월수 오류입니다.\r다시 입력해주세요.';
    case '해외카드 미사용 가맹점':
      return '카드결제에 실패했습니다.\r카드 정보를 다시 확인해주세요.';
    default:
      return '카드결제에 실패했습니다.\r잠시 후 다시 시도해주세요.';
  }
};

export const convertRegisterCardFailMessage = (message: string) => {
  switch (message) {
    case '유효기간경과':
      return '유효기간이 일치하지 않습니다.\r다시 입력해주세요.';
    case '생년월일 불일치':
      return '생년월일이 일치하지 않습니다.\r생년월일 3회 입력 오류시 온라인결제가 제한됩니다.\r다시 입력해주세요.';
    case '카드번호 오류':
      return '카드번호가 일치하지 않습니다.\r다시 입력해주세요.';
    case '비밀번호횟수초과':
      return '입력 오류 횟수 초과로 입력이 제한됩니다.\r카드사 측에 문의해주세요.';
    case '잔액부족':
      return '카드잔액이 부족합니다.\r다른 카드 결제 또는 카드 잔액을 확인해주세요.';
    case '할부개월수 오류':
      return '할부 개월수 오류입니다.\r다시 입력해주세요.';
    case '해외카드 미사용 가맹점':
      return '카드 등록에 실패했습니다.\r카드 정보를 다시 확인해주세요.';
    default:
      return '카드 등록에 실패했습니다.\r잠시 후 다시 시도해주세요.';
  }
};

export const convertProductName = (product: string) => {
  switch (product) {
    case '5+1 혜택상품':
      return '학기 상품';
    case '6+6 혜택상품':
      return '1년 무제한 상품';
    default:
      return '월 정기상품';
  }
};

export const convertPeriodToProductName = (period: string) => {
  switch (period) {
    case 'month':
      return '월 정기상품';
    case 'sixMonth':
      return '학기 상품';
    case 'year':
      return '1년 무제한 상품';
    default:
      return '월 정기상품';
  }
};

export const formatEndDate = (enddate: string) => {
  const regex = new RegExp(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/g, 'i');
  if (regex.test(enddate)) {
    const date_enddate = new Date(enddate);

    return setLocaleDateString(date_enddate, '-');
  } else {
    return enddate;
  }
};

export const convertInstallmentCount = (value: string) => {
  if (value === '일시불') {
    return 0;
  } else {
    return Number(value.replace('개월', ''));
  }
};

export function getFormattedLastDayOfMonth() {
  const currentDate = dayjs();
  const nextMonth = currentDate.add(1, 'month').startOf('month');
  const lastDayOfMonth = nextMonth.subtract(1, 'day');
  const formattedDate = lastDayOfMonth.format('YYYY. MM. DD');
  return formattedDate;
}

export function openStudentApp(device: string) {
  if (device == 'android') {
    location.replace(app_link.android);
  } else if (device == 'ios') {
    location.replace(app_link.ios);
  } else {
    // pc
    const STUDENT_LONGVOCA_URL =
      process.env.REACT_APP_NODE_ENV === 'production'
        ? 'https://student.longedu.co.kr/login?mission=true'
        : 'https://longstudent.irontrain.co.kr/login?mission=true';

    const width = window.screen.width || window.outerWidth;
    const height = window.screen.height || window.outerHeight;

    const params = [
      `width=${width}`,
      `height=${height}`,
      'fullscreen=yes',
      'directories=no',
      'location=no',
      'menubar=no',
      'resizable=0',
      'scrollbars=no',
      'status=no',
      'toolbar=no',
    ].join(',');

    const newWin = window.open(STUDENT_LONGVOCA_URL, '_blank', params);

    if (newWin) {
      if (window['focus']) {
        newWin.focus();
      }
    }
    return false;
  }
}

export const checkDevice = () => {
  const ua = navigator.userAgent.toLowerCase();

  if (ua.indexOf('android') > -1) {
    return 'android';
  } else if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1) {
    return 'ios';
  } else {
    if (ua.indexOf('mac os x') !== -1) {
      if (navigator.maxTouchPoints > 0) {
        return 'ios';
      }
    }
    return 'other';
  }
};

export const getPersonalProductPackageAndPeriod = (productType: string) => {
  const period = productType.includes('H') ? 'H' : productType.includes('M') ? 'M' : 'Y';
  if (['A003Y', 'A003H', 'A003M'].includes(productType)) {
    return {
      package: 'all',
      period: period,
    };
  } else if (['S003Y', 'S003H', 'S003M'].includes(productType)) {
    return {
      package: 'speak',
      period: period,
    };
  } else if (['V003Y', 'V003H', 'V003M'].includes(productType)) {
    return {
      package: 'voca',
      period: period,
    };
  } else {
    return {
      package: 'grammar',
      period: period,
    };
  }
};

export const getPersonalProductPackageName = (productType: string) => {
  const period = productType.includes('H') ? '6개월' : productType.includes('M') ? '1개월' : '1년';
  let product_name = '';
  if (['A003Y', 'A003H', 'A003M'].includes(productType)) {
    product_name = `올인원 클래스 ${period}`;
    return product_name;
  } else if (['S003Y', 'S003H', 'S003M'].includes(productType)) {
    product_name = `실전회화 클래스 ${period}`;
    return product_name;
  } else if (['V003Y', 'V003H', 'V003M'].includes(productType)) {
    product_name = `기초어휘 클래스 ${period}`;
    return product_name;
  } else if (['G003Y', 'G003H', 'G003M'].includes(productType)) {
    product_name = `핵심문법 클래스 ${period}`;
    return product_name;
  } else {
    return false;
  }
};
